@import '../../styles/index';

.base {
  position: relative;
}

.map-container {
  position: relative;
  overflow: hidden;
  height: calc(100svh - 3.75rem);
  width: 100vw;
  margin-bottom: 0;

  @include media-from(sm) {
    height: calc(100svh - 5rem);
  }
}

.map-details {
  position: absolute;
  z-index: 1;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 1.5rem;
  transform: translateY(1rem) scale(0.9);
  opacity: 0;
  pointer-events: none;

  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  @include media-from(sm) {
    left: unset;

    margin-left: auto;
    margin-right: auto;
    max-width: 25.7rem;
  }
}

.map-details-visible {
  pointer-events: all;
  opacity: 1;
  transform: translateY(0) scale(1);
}

.search-box {
  position: absolute;
  top: 1rem;
  left: 0.5rem;
  width: calc(100% - 1rem);

  background-color: $neutral-0;
  z-index: 1;

  @include media-from(sm) {
    width: unset;
    position: relative;
  }

  @include media-from(sm) {
    top: 1rem;
    left: 1rem;
    position: absolute;
  }
}

.search-box-inner {
  border-radius: $border-radius-default;
}

.legend {
  display: flex;
  padding: 1.25rem 1.5rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem 1.25rem;

  position: absolute;
  left: 1rem;
  right: 1rem;
  bottom: 1rem;

  border-radius: 0.75rem;
  background: $neutral-0;
  box-shadow: 0px -4px 24px 0px rgba(0, 0, 0, 0.08);

  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  @include media-from(sm) {
    left: 1rem;
    bottom: 1rem;
    right: unset;
    gap: 1.25rem;
  }
}

.legend-hidden {
  opacity: 0;
  transform: scale(0.9);
}

.legend-item {
  display: flex;
  align-items: center;
  text-align: center;
  line-height: 1.375;
  white-space: nowrap;
  font-weight: $font-weight-medium;
  color: var(--accent--100, $surrogate-9);

  &::before {
    content: '';
    display: block;
    border-style: solid;
    border-width: 0.125rem;
    border-color: var(--accent--100, $surrogate-9);
    background-color: var(--accent--20, $surrogate-4);
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
}
